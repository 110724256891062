import React from "react";

import Navigation from "../components/Navigation";

//import video_test from "../assets/video/Legge.mp4";
import logo from "../assets/aris_logo_bgalpha.png";
import LateralMenu from "../components/LateralMenu";

export default function P8Aris_2() {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        backgroundColor: "white",
      }}
    >
      <LateralMenu selected={2} image={logo} />
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "8%",
          paddingRight: "8%",
        }}
      >
        <h2>Inoltre ...</h2>
        <ul
          style={{
            color: "#333333",
            fontSize: "1.5em",
            fontWeight: 100,
            marginTop: 0,
          }}
        >
          <li style={{ marginBottom: 20, marginTop: 20 }}>
            <span style={{ fontWeight: 700 }}>Responsabilità limitata: </span> i
            membri della cooperativa non sono personalmente responsabili per i
            debiti o gli obblighi finanziari della cooperativa. Questo offre una
            protezione legale e limita il rischio finanziario per i membri.
          </li>
          <li style={{ marginBottom: 20, marginTop: 20 }}>
            <span style={{ fontWeight: 700 }}>
              Passaggio intergenerazionale garantito:{" "}
            </span>
            la forma cooperativa prevede l’indivisibilità del patrimonio,
            peculiarità che mette al sicuro gli asset della CER, prolungandone
            la vita.
          </li>
          <li style={{ marginBottom: 20, marginTop: 20 }}>
            <span style={{ fontWeight: 700 }}>
              Minori costi di costituzione:{" "}
            </span>
            della cooperativa rispetto a una SRL o SPA o di un’associazione con
            personalità giuridica
          </li>
          <li style={{ marginBottom: 20, marginTop: 20 }}>
            <span style={{ fontWeight: 700 }}>
              Accesso a finanziamenti e agevolazioni:{" "}
            </span>
            Le cooperative hanno maggiori opportunità di accesso a finanziamenti
            e agevolazioni rispetto ad altre forme giuridiche. Possono
            beneficiare di incentivi fiscali, finanziamenti agevolati,
            sovvenzioni o fondi dedicati allo sviluppo delle rinnovabili.
          </li>
        </ul>
      </div>

      <Navigation back="/p8_aris" forward="/p9" />
    </div>
  );
}
