import React from "react";
import { motion } from "framer-motion";

import Assistant from "../components/Assistant";
import Navigation from "../components/Navigation";

import video_test from "../assets/video/Ieri-domani-oggi_3.mp4";
import bgImg2 from "../assets/sfondoP5-2.jpg";
import bgImg3 from "../assets/sfondoP5-3.jpg";

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      delayChildren: 0.5,
      staggerChildren: 2,
    },
  },
};

const childVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 2 },
  },
};

function P5() {
  return (
    <motion.div
      style={{ height: "100%" }}
      variants={containerVariants}
      animate="visible"
      initial="hidden"
    >
      <div
        variants={childVariants}
        style={{
          backgroundImage: `url(${bgImg2})`,
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></div>
      <motion.div
        variants={childVariants}
        style={{
          backgroundImage: `url(${bgImg3})`,
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></motion.div>
      <Assistant
        src={video_test}
        position={{
          zIndex: 10,
          position: "absolute",
          bottom: "30px",
          left: "41.7%",
        }}
      />
      <Navigation back="/p5_2" forward="/p6" />
    </motion.div>
  );
}

export default P5;
