import React from "react";

import Navigation from "../components/Navigation";
import LateralSimulation from "../components/LateralSimulation";

import video from "../assets/video/SIM_Ripartizione.mp4";
import imm1 from "../assets/Incentivo.jpg";
import imm2 from "../assets/Votazione.jpg";

export default function P22() {
  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        display: "flex",
      }}
    >
      <LateralSimulation video={video} />
      <div
        style={{
          height: "100%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        <p style={{ fontSize: "1.5em", fontWeight: 100 }}>
          Come arrivano e come utilizzare gli incentivi della CER?
        </p>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 4 }}>
            <p style={{ fontSize: "1.2em", fontWeight: 100 }}>
              L’incentivo del GSE verrà erogato direttamente sul{" "}
              <span style={{ fontWeight: 700 }}>conto corrente della CER.</span>
            </p>
          </div>
          <div style={{ flex: 2, textAlign: "center" }}>
            <img
              src={imm1}
              width="60%"
              alt="Un signore del GSE inserisce una moneta nel salvadanaio della CER."
            />
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 2, textAlign: "center" }}>
            <img
              src={imm2}
              width="60%"
              alt="Scatola per inserire il foglio del voto."
            />
          </div>
          <div style={{ flex: 4 }}>
            <p style={{ fontSize: "1.2em", fontWeight: 100 }}>
              Con una <span style={{ fontWeight: 700 }}>votazione</span> i
              membri della CER decideranno come ripartire l'incentivo. Per
              esempio:
            </p>
            <ul style={{ fontSize: "1.2em", fontWeight: 100 }}>
              <li style={{ marginBottom: 20 }}>
                {" "}
                a metà tra produttori e consumatori o premiando i consumatori
                che cambiano abitudini per generare consumi buoni
              </li>
              <li>
                {" "}
                in parti uguali tra consumatori (produttori) oppure in base ai
                kWh scambiati da ciascuno{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Navigation back="/p21" forward="/p23" />
    </div>
  );
}
