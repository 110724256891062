import logo from "../assets/light_logo.png";
import logoAris from "../assets/aris_logo_bgalpha.png";

import useAmico from "../hooks/useAmico";
import { Grid } from "@mui/material";

function LogosBanner() {
  const { amicoCER } = useAmico();

  return (
    <>
      {amicoCER == "ArisFormazione" ? (
        <Grid
          container
          justifyContent="space-between"
          pr={10}
          pl={10}
          alignItems="center"
          height="25%"
          bgcolor="#fff"
        >
          <img src={logoAris} height={"70%"} />
          <img src={logo} height={"70%"} />
        </Grid>
      ) : (
        <div
          style={{
            height: "25%",
            width: "100%",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: "150px",
          }}
        >
          <img src={logo} height={"80%"} />
        </div>
      )}
    </>
  );
}

export default LogosBanner;
