import React, { useEffect, useState } from "react";
import { getCookie } from "../utils/manageCookies";

import Navigation from "../components/Navigation";
import LateralSimulation from "../components/LateralSimulation";
import PlotArea from "../components/plots/PlotArea";

import simulationRequest from "../utils/simulationRequest";

import video from "../assets/video/SIM_Analisi24h.mp4";

export default function P27() {
  const [serverReply, setServerReply] = useState({});

  useEffect(() => {
    const sessionID = getCookie("sessionID");
    simulationRequest({ sessionID }, "incentivoGiorno", setServerReply);
    //result:{}
  }, []);

  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        display: "flex",
      }}
    >
      <LateralSimulation video={video} />
      <div
        style={{
          height: "100%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        <p style={{ fontSize: "1.5em", fontWeight: 100 }}>
          Consumi "buoni" e scambio incentivato
        </p>
        {serverReply.plotData && (
          <PlotArea
            datafield={serverReply.plotData}
            divStyle={{
              width: "100%",
              height: "45%",
            }}
          />
        )}

        <div
          style={{
            width: "100%",
            borderStyle: "solid",
            borderWidth: "4px 0px 0px",
            borderColor: "#BDBDBD",
          }}
        >
          <table width="100%">
            <tr align="center" style={{ fontSize: "1.2em", fontWeight: 100 }}>
              <td>Produzione disponibile</td>
              <td>Consumo buono</td>
              <td>Consumo totale</td>
            </tr>
            <tr align="center" style={{ fontSize: "1.75em", fontWeight: 700 }}>
              <td style={{ color: global.producerColor }}>
                {serverReply.plotData
                  ?.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue.prod,
                    0
                  )
                  ?.toFixed(0)}{" "}
                kWh
              </td>
              <td style={{ color: global.consumiBuoniColor }}>
                {serverReply.plotData
                  ?.reduce(
                    (accumulator, currentValue) =>
                      currentValue.prod > 0 && currentValue.cons > 0
                        ? accumulator +
                          Math.min(currentValue.prod, currentValue.cons)
                        : accumulator,
                    0
                  )
                  ?.toFixed(0)}{" "}
                kWh
              </td>
              <td style={{ color: global.consumerColor }}>
                {serverReply.plotData
                  ?.reduce(
                    (accumulator, currentValue) =>
                      accumulator + currentValue.cons,
                    0
                  )
                  ?.toFixed(0)}{" "}
                kWh
              </td>
            </tr>
          </table>
        </div>
      </div>

      <Navigation back="/p26" forward="/p28" />
    </div>
  );
}
