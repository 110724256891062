import React, { useState, createRef } from "react";
import ReactPlayer from "react-player";

import audioIcon from "../assets/audio_icon.png";

export default function Assistant({ position = {}, src, image }) {
  const [playing, setPlaying] = useState(false);
  const ref = createRef();
  console.log(image);

  return (
    <div
      style={{
        flexDirection: "column",
        cursor: src ? `url(${audioIcon}), auto` : "default",
      }}
    >
      <div
        id="videoWrapper"
        style={position}
        onClick={() => setPlaying(!playing)}
      >
        {src ? (
          <ReactPlayer
            ref={ref}
            className="react-player"
            url={src}
            width="100%"
            height="100%"
            playing={playing}
            //controls={true}
          />
        ) : (
          <img src={image} width="60%" height="60%"></img>
        )}
      </div>
      {/*<div>
        <button
          onClick={() => {
            ref.current.seekTo(0);
          }}
        >
          Ricomincia
        </button>
      </div>
        */}
    </div>
  );
}
