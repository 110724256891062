import React from "react";
import { useNavigate } from "react-router-dom";

import backArrow from "../assets/back.png";
import forwardArrow from "../assets/forward.png";

function Navigation({ back, forward }) {
  const navigate = useNavigate();

  return (
    <>
      {back && (
        <div
          style={{
            position: "absolute",
            left: 0,
            height: "100%",
            width: "5%",
            //cursor: "pointer",
            cursor: `url(${backArrow}), auto`,
          }}
          onClick={() => navigate(back)}
        ></div>
      )}
      {forward && (
        <div
          style={{
            position: "absolute",
            right: 0,
            height: "100%",
            width: "5%",
            //cursor: "pointer",
            cursor: `url(${forwardArrow}), auto`,
          }}
          onClick={() => navigate(forward)}
        ></div>
      )}
    </>
  );
}

export default Navigation;
