import React from "react";
import { motion } from "framer-motion";

import Assistant from "../components/Assistant";
import Navigation from "../components/Navigation";

import video_test from "../assets/video/Newspapers.mp4";
import bgImg from "../assets/sfondoP2-1.jpg";
import bgImg2 from "../assets/sfondoP2-2.jpg";

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      delayChildren: 1,
      staggerChildren: 4,
    },
  },
};

const lastChild = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 2 },
  },
};

function P2() {
  return (
    <motion.div
      style={{ height: "100%" }}
      variants={containerVariants}
      animate="visible"
      initial="hidden"
    >
      <div
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></div>

      <motion.div
        variants={lastChild}
        style={{
          backgroundImage: `url(${bgImg2})`,
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></motion.div>

      <Assistant
        src={video_test}
        position={{
          zIndex: 10,
          position: "absolute",
          bottom: "30px",
          left: "30px",
        }}
      />
      <Navigation back="/p1" forward="/p3" />
    </motion.div>
  );
}

export default P2;
