import React, { useEffect, useState } from "react";
import { getCookie } from "../utils/manageCookies";

import Navigation from "../components/Navigation";
import LateralSimulation from "../components/LateralSimulation";
import PlotVertColumn from "../components/plots/PlotVertColumn";

import simulationRequest from "../utils/simulationRequest";

import video from "../assets/video/SIM_GiocoFinale.mp4";
import schoolImg from "../assets/scuola.png";
import chargerImg from "../assets/colonnina.png";
import producerImg from "../assets/impianto.png";

import FakeUserCard from "../components/FakeUserCard";

export default function P29() {
  const [serverReply, setServerReply] = useState({});
  const [Nscuole, setNscuole] = useState(0);
  const [Ncharger, setNcharger] = useState(0);
  const [kWPower, setkWPower] = useState(0);

  useEffect(() => {
    const sessionID = getCookie("sessionID");
    simulationRequest(
      { sessionID, Nscuole, Ncharger, kWPower },
      "fakeUsers",
      setServerReply
    );
    //result:{}
  }, [Nscuole, Ncharger, kWPower]);

  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        display: "flex",
      }}
    >
      <LateralSimulation video={video} />
      <div
        style={{
          height: "100%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        <p style={{ fontSize: "1.5em", fontWeight: 500 }}>
          La diversità premia
        </p>
        {serverReply.plotData && (
          <PlotVertColumn
            datafield={serverReply.plotData}
            fourth={true}
            divStyle={{
              width: "100%",
              height: "40%",
            }}
          />
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "20%",
            width: "100%",
            marginBottom: 20,
          }}
        >
          <FakeUserCard
            img={producerImg}
            name={"IMPIANTO"}
            value={kWPower}
            setValue={setkWPower}
            kW={true}
          />
          <FakeUserCard
            img={schoolImg}
            name={"SCUOLA"}
            value={Nscuole}
            setValue={setNscuole}
          />
          <FakeUserCard
            img={chargerImg}
            name={"COLONNINA"}
            value={Ncharger}
            setValue={setNcharger}
          />
        </div>
        <div
          style={{
            width: "100%",
            borderStyle: "solid",
            borderWidth: "4px 0px 0px",
            borderColor: "#BDBDBD",
            paddingTop: "10px",
          }}
        >
          <table width="100%">
            <tr align="center" style={{ fontSize: "1.2em", fontWeight: 100 }}>
              <td>Incentivo originale</td>
              <td>Nuovo incentivo</td>
            </tr>
            <tr align="center" style={{ fontSize: "1.75em", fontWeight: 700 }}>
              <td style={{ color: "#00BFA5" }}>
                {serverReply.totale_incentivoOriginale &&
                  serverReply.totale_incentivoOriginale.toFixed(2)}{" "}
                €
              </td>
              <td
                style={{
                  color: "white",
                }}
              >
                <span
                  style={{
                    backgroundColor: "#00BFA5",
                    borderRadius: 40,
                    padding: "4px 16px",
                  }}
                >
                  {serverReply?.totale_incentivoCER &&
                    serverReply.totale_incentivoCER.toFixed(2)}{" "}
                  €
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <Navigation back="/p28" forward="/p30" />
    </div>
  );
}
