import React from "react";
import QRCode from "react-qr-code";

import Navigation from "../components/Navigation";
import Assistant from "../components/Assistant";

import video from "../assets/video/QR_premiosingolo.mp4";
import logo from "../assets/light_logo.png";
import LogosBanner from "../components/LogosBanner";

export default function P25() {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(#F99158, #EF5350)`,
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: "75%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <p
          style={{
            margin: "0px",
            fontSize: "3em",
            color: "white",
            fontWeight: "100",
          }}
        >
          La Vostra <span style={{ fontWeight: 700 }}>CER</span>
        </p>
        <div
          style={{
            height: "50%",
            width: "50%",
            backgroundColor: "white",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            paddingTop: "50px",
            paddingBottom: "50px",
          }}
        >
          <Assistant
            src={video}
            position={{
              zIndex: 10,
              position: "absolute",
              left: "50px",
              top: "30%",
            }}
          />
          <p style={{ width: "33%", fontSize: "1.5em", fontWeight: 100 }}>
            Inquadra con il cellulare questo QRcode per conoscere il tuo
            incentivo
          </p>
          <QRCode value={`${process.env.REACT_APP_CLIENT_URL}risIndividuali`} />
        </div>
      </div>
      <LogosBanner />
      <Navigation back="/p24" forward="/p26" />
    </div>
  );
}
