import React from "react";

import Navigation from "../components/Navigation";

import video_test from "../assets/video/Orari_buoni.mp4";
import img from "../assets/orariBuoni.jpg";
import LateralMenu from "../components/LateralMenu";
import useAmico from "../hooks/useAmico";

export default function P12() {
  const { amicoCER } = useAmico();

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        backgroundColor: "white",
      }}
    >
      <LateralMenu
        video={video_test}
        selected={amicoCER == "ArisFormazione" ? 6 : 5}
      />
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "8%",
          paddingRight: "8%",
        }}
      >
        <div>
          <img src={img} width={"100%"}></img>
        </div>
        <p style={{ color: "#00BFA5", fontSize: "3em", fontWeight: 800 }}>
          Importante!
        </p>
      </div>

      <Navigation back="/p11" forward="/p13" />
    </div>
  );
}
