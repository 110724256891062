import React from "react";
import { motion } from "framer-motion";

import Assistant from "../components/Assistant";
import Navigation from "../components/Navigation";

import bgImg from "../assets/sfondoP4.jpg";
import video_test from "../assets/video/Eolica_Biomasse_Solare.mp4";

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      delayChildren: 0.5,
      staggerChildren: 1,
    },
  },
};

const childVariant = {
  hidden: { opacity: 0, y: -20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 1 },
  },
};

const leftMovingVariant = {
  hidden: { opacity: 0, x: 20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 1 },
  },
};

function P4() {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <motion.div
        variants={containerVariants}
        animate="visible"
        initial="hidden"
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-around",
          paddingTop: "10px",
        }}
      >
        <motion.p
          variants={childVariant}
          className="title"
          style={{ color: "#00BFA5" }}
        >
          Eolica
        </motion.p>
        <motion.p
          variants={childVariant}
          className="title"
          style={{ color: "#FFF" }}
        >
          Biomasse
        </motion.p>
        <motion.p
          variants={childVariant}
          className="title"
          style={{ color: "#F4FF81" }}
        >
          Solare
        </motion.p>
        <motion.p
          variants={leftMovingVariant}
          style={{
            color: "#FFF",
            position: "absolute",
            bottom: "0px",
            right: "40px",
            fontSize: "3em",
            fontWeight: "bold",
          }}
        >
          ... e altre
        </motion.p>
        <Navigation back="/p3" forward="/p5_1" />
      </motion.div>
      <Assistant
        src={video_test}
        position={{
          zIndex: 10,
          position: "absolute",
          bottom: "30px",
          left: "41.7%",
        }}
      />
    </div>
  );
}

export default P4;
