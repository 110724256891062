import React, { useEffect, useState } from "react";
import { getCookie } from "../utils/manageCookies";

import Navigation from "../components/Navigation";
import LateralSimulation from "../components/LateralSimulation";
import PlotTorta from "../components/plots/PlotTorta";

import simulationRequest from "../utils/simulationRequest";

import video from "../assets/video/SIM_letturaCER.mp4";

export default function P20() {
  const [serverReply, setServerReply] = useState({});
  const [datafield, setDatafield] = useState([]);

  useEffect(() => {
    const sessionID = getCookie("sessionID");
    simulationRequest({ sessionID }, "generalInfo", setServerReply);
    //console.log(serverReply);
    //result:{nMembri, nProducer, nConsumer, nProsumer, power}

    setDatafield([
      { category: "Consumatori", value: serverReply.nConsumer },
      { category: "Produttori", value: serverReply.nProducer },
      { category: "Prosumers", value: serverReply.nProsumer },
    ]);
  }, [serverReply.nConsumer]);

  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        display: "flex",
      }}
    >
      <LateralSimulation video={video} />
      <div
        style={{
          height: "100%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        <p style={{ fontSize: "1.5em", fontWeight: 100 }}>
          Questa CER è composta da{" "}
          <span style={{ fontWeight: 700 }}>{serverReply.nMembri} membri</span>{" "}
          così distribuiti
        </p>
        <PlotTorta
          datafield={datafield}
          divStyle={{
            width: "100%",
            height: "33%",
          }}
        />
        <table width="100%">
          <tr align="center" style={{ fontSize: "1.2em", fontWeight: 100 }}>
            <td>Produttori</td>
            <td>Prosumers</td>
            <td>Consumatori</td>
          </tr>
          <tr align="center" style={{ fontSize: "1.75em", fontWeight: 700 }}>
            <td style={{ color: global.producerColor }}>
              {serverReply.nProducer}
            </td>
            <td style={{ color: global.prosumerColor }}>
              {serverReply.nProsumer}
            </td>
            <td style={{ color: global.consumerColor }}>
              {serverReply.nConsumer}
            </td>
          </tr>
        </table>
        <div
          style={{
            width: "100%",
            borderStyle: "solid",
            borderWidth: "4px 0px 0px",
            borderColor: "#BDBDBD",
          }}
        >
          <p
            style={{ textAlign: "center", fontSize: "1.5em", fontWeight: 100 }}
          >
            La potenza totale degli impianti istallati è di <br />
            <span style={{ fontWeight: 700, fontSize: "1.5em" }}>
              {serverReply.power} kW
            </span>
          </p>
        </div>
      </div>

      <Navigation back="/p19" forward="/p21" />
    </div>
  );
}
