import React from "react";

import Navigation from "../components/Navigation";

import video_test from "../assets/video/Definizione_CER.mp4";
import LateralMenu from "../components/LateralMenu";

export default function P7() {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        backgroundColor: "white",
      }}
    >
      <LateralMenu video={video_test} selected={0} />
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "8%",
          paddingRight: "8%",
          fontSize: "2em",
          textAlign: "center",
        }}
      >
        <p style={{ color: "#333333" }}>
          L’unione di più soggetti in una{" "}
          <span style={{ color: "#00BFA5", fontWeight: 800 }}>
            Comunità Energetica Rinnovabile
          </span>{" "}
          fa incontrare{" "}
          <span style={{ color: "#00BFA5", fontWeight: 800 }}>produttori</span>{" "}
          e{" "}
          <span style={{ color: "#00BFA5", fontWeight: 800 }}>consumatori</span>{" "}
          locali ottimizzando il loro{" "}
          <span style={{ color: "#00BFA5", fontWeight: 800 }}>
            scambio di energia
          </span>{" "}
          e questo viene incentivato con una{" "}
          <span style={{ color: "#00BFA5", fontWeight: 800 }}>
            tariffa premio
          </span>
        </p>
      </div>

      <Navigation back="/p6" forward="/p8" />
    </div>
  );
}
