import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { getCookie } from "../utils/manageCookies";

import { toast } from "react-toastify";

import Navigation from "../components/Navigation";

import logo from "../assets/light_logo.png";
import logoAris from "../assets/aris_logo.jpg";
import { Button, Card, Grid, Typography } from "@mui/material";

function P30() {
  const [sID, setSID] = useState();
  const [amicoCER, setAmicoCER] = useState();

  useEffect(() => {
    const isToken = () => {
      const token = getCookie("token");
      if (token) {
        const { sessionID, amico } = jwt_decode(token);
        setAmicoCER(amico);
        setSID(sessionID);
      }
    };

    isToken();
  }, []);

  const sendData = async () => {
    //send request with token
    try {
      const token = getCookie("token");
      await fetch(`${process.env.REACT_APP_API_URL}/api/sendSessionLog`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      toast.success("Resoconto inviato correttamente");
    } catch (error) {
      toast.error("Errore durante l'invio, riprova più tardi");
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="space-evenly"
      height="100%"
      width="100%"
    >
      {amicoCER == "ArisFormazione" ? (
        <Grid item width="60%">
          <Card sx={{ p: 2 }}>
            <Grid container>
              <Grid item xs={6} container justifyContent="center">
                <img src={logoAris} width="30%" />
              </Grid>
              <Grid item xs={6} container justifyContent="center">
                <Typography variant="h6">
                  Invia ad Aris il resoconto di questa sessione
                </Typography>
                <Button variant="contained" sx={{ px: 6 }} onClick={sendData}>
                  Invia
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ) : (
        <Grid item>
          <Typography variant="h1" color="primary">
            Grazie!
          </Typography>
        </Grid>
      )}
      <Grid item container alignItems="center" justifyContent="space-evenly">
        <Grid item>
          <p style={{ fontSize: "1.5em", color: "#0097A7", fontWeight: 100 }}>
            CERtrude è un prodotto di
          </p>
        </Grid>
        <Grid item>
          <img src={logo} height={200} />
        </Grid>
        <Grid item>
          <p style={{ fontSize: "1.5em", color: "#0097A7", fontWeight: 100 }}>
            www.likelight.it
          </p>
        </Grid>
      </Grid>
      <Navigation back="/p29" />
    </Grid>
  );
}

export default P30;
