import React, { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function PlotTorta({ datafield, divStyle }) {
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");

    // Set themes
    var myTheme = am5.Theme.new(root);

    myTheme.rule("Label").setAll({
      fill: am5.color(0x252525),
      fontSize: "1.2em",
      fontFamily: "BioRhyme",
      fontWeight: "300",
    });

    //color set
    myTheme
      .rule("ColorSet")
      .set("colors", [
        am5.color(global.consumerColor),
        am5.color(global.producerColor),
        am5.color(global.prosumerColor),
      ]);

    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root), myTheme]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    var chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
      })
    );

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    var series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "category",
      })
    );

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(datafield);

    // Play initial series animation
    // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [datafield]);

  return <div id="chartdiv" style={divStyle}></div>;
}

export default PlotTorta;
