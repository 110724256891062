import React, { useEffect, useState } from "react";
import { getCookie } from "../utils/manageCookies";

import Navigation from "../components/Navigation";
import LateralSimulation from "../components/LateralSimulation";
import PlotVertColumn from "../components/plots/PlotVertColumn";

import simulationRequest from "../utils/simulationRequest";

import video from "../assets/video/SIM_Analisi12mesi.mp4";

export default function P28() {
  const [serverReply, setServerReply] = useState({});

  useEffect(() => {
    const sessionID = getCookie("sessionID");
    simulationRequest({ sessionID }, "estraiMesi", setServerReply);
    //result:{plotData:{mese, prod, cons, incentivato}}
  }, []);

  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        display: "flex",
      }}
    >
      <LateralSimulation video={video} />
      <div
        style={{
          height: "100%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        <p style={{ fontSize: "1.5em", fontWeight: 100 }}>
          Analisi mese per mese
        </p>
        {serverReply.plotData && (
          <PlotVertColumn
            datafield={serverReply.plotData}
            divStyle={{
              width: "100%",
              height: "50%",
            }}
          />
        )}

        <div
          style={{
            width: "100%",
            borderStyle: "solid",
            borderWidth: "4px 0px 0px",
            borderColor: "#BDBDBD",
            paddingTop: "10px",
          }}
        >
          <table width="100%">
            <tr align="center" style={{ fontSize: "1.2em", fontWeight: 100 }}>
              <td>Mese migliore</td>
              <td>Mese peggiore</td>
            </tr>
            <tr align="center" style={{ fontSize: "1.75em", fontWeight: 700 }}>
              <td style={{ color: "#00BFA5" }}>
                {serverReply.plotData &&
                  (
                    0.12 *
                    serverReply.plotData.reduce((prev, current) =>
                      prev.incentivato > current.incentivato ? prev : current
                    ).incentivato
                  ).toFixed(2)}{" "}
                €
              </td>
              <td style={{ color: "#FF9100" }}>
                {serverReply.plotData &&
                  (
                    0.12 *
                    serverReply.plotData.reduce((prev, current) =>
                      prev.incentivato < current.incentivato ? prev : current
                    ).incentivato
                  ).toFixed(2)}{" "}
                €
              </td>
            </tr>
            <tr align="center" style={{ fontSize: "1em", fontWeight: 100 }}>
              <td>
                {serverReply.plotData &&
                  serverReply.plotData.reduce((prev, current) =>
                    prev.incentivato > current.incentivato ? prev : current
                  ).mese}
              </td>
              <td>
                {serverReply.plotData &&
                  serverReply.plotData.reduce((prev, current) =>
                    prev.incentivato < current.incentivato ? prev : current
                  ).mese}
              </td>
            </tr>
          </table>
        </div>
      </div>

      <Navigation back="/p27" forward="/p29" />
    </div>
  );
}
