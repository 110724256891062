import { Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";

import ProtectedRoute from "./components/ProtectedRoute";
import { AuthContextProvider } from "./context/AuthContextProvider";

import Welcome from "./pages/Welcome";
import Intro from "./pages/Intro";
import IntroAris from "./pages/IntroAris";
import P1 from "./pages/P1";
import P2 from "./pages/P2";
import P3 from "./pages/P3";
import P4 from "./pages/P4";
import P5_1 from "./pages/P5_1";
import P5_2 from "./pages/P5_2";
import P5_3 from "./pages/P5_3";
import P6 from "./pages/P6";
import P7 from "./pages/P7";
import P8 from "./pages/P8";
import P8Aris from "./pages/P8Aris";
import P8Aris_2 from "./pages/P8Aris_2";
import P9 from "./pages/P9";
import P10 from "./pages/P10";
import P11 from "./pages/P11";
import P12 from "./pages/P12";
import P13 from "./pages/P13";
import P14 from "./pages/P14";
import P15 from "./pages/P15";
import P16 from "./pages/P16";
import P17 from "./pages/P17";
import P18 from "./pages/P18";
import P19 from "./pages/P19";
import P20 from "./pages/P20";
import P21 from "./pages/P21";
import P22 from "./pages/P22";
import P23 from "./pages/P23";
import P24 from "./pages/P24";
import P25 from "./pages/P25";
import P26 from "./pages/P26";
import P27 from "./pages/P27";
import P28 from "./pages/P28";
import P29 from "./pages/P29";
import P30 from "./pages/P30";

const themeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#0097A7",
    },
    secondary: {
      main: "#1DE9B6",
    },
    dark: {
      main: global.blueColor,
      hover: "#56789C",
    },
    info: {
      main: "#edf2f4",
    },
    white: {
      main: "#fff",
      hover: "#dbf0ff",
    },
  },
};

const theme = createTheme(themeOptions);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div id="appContent">
        <AuthContextProvider>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route path="/" exact element={<Welcome />} />
              <Route path="/intro" exact element={<Intro />} />
              <Route path="/intro_aris" exact element={<IntroAris />} />
              <Route path="/p1" exact element={<P1 />} />
              <Route path="/p2" exact element={<P2 />} />
              <Route path="/p3" exact element={<P3 />} />
              <Route path="/p4" exact element={<P4 />} />
              <Route path="/p5_1" exact element={<P5_1 />} />
              <Route path="/p5_2" exact element={<P5_2 />} />
              <Route path="/p5_3" exact element={<P5_3 />} />
              <Route path="/p6" exact element={<P6 />} />
              <Route path="/p7" exact element={<P7 />} />
              <Route path="/p8" exact element={<P8 />} />
              <Route path="/p8_aris" exact element={<P8Aris />} />
              <Route path="/p8_aris_2" exact element={<P8Aris_2 />} />
              <Route path="/p9" exact element={<P9 />} />
              <Route path="/p10" exact element={<P10 />} />
              <Route path="/p11" exact element={<P11 />} />
              <Route path="/p12" exact element={<P12 />} />
              <Route path="/p13" exact element={<P13 />} />
              <Route path="/p14" exact element={<P14 />} />
              <Route path="/p15" exact element={<P15 />} />
              <Route path="/p16" exact element={<P16 />} />
              <Route path="/p17" exact element={<P17 />} />
              <Route path="/p18" exact element={<P18 />} />
              <Route path="/p19" exact element={<P19 />} />
              <Route path="/p20" exact element={<P20 />} />
              <Route path="/p21" exact element={<P21 />} />
              <Route path="/p22" exact element={<P22 />} />
              <Route path="/p23" exact element={<P23 />} />
              <Route path="/p24" exact element={<P24 />} />
              <Route path="/p25" exact element={<P25 />} />
              <Route path="/p26" exact element={<P26 />} />
              <Route path="/p27" exact element={<P27 />} />
              <Route path="/p28" exact element={<P28 />} />
              <Route path="/p29" exact element={<P29 />} />
              <Route path="/p30" exact element={<P30 />} />
            </Route>
          </Routes>
        </AuthContextProvider>

        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </ThemeProvider>
  );
}

export default App;
