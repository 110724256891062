import React from "react";
import { motion } from "framer-motion";

import Navigation from "../components/Navigation";
import Assistant from "../components/Assistant";

import video from "../assets/video/La_City.mp4";
import bgImg from "../assets/sfondoP16-3.jpg";
import bgImg2 from "../assets/sfondoP16-4.jpg";

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      delayChildren: 1.5,
      staggerChildren: 0.6,
    },
  },
};

const childVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 2 },
  },
};

export default function P18() {
  return (
    <motion.div
      style={{
        height: "100%",
        backgroundImage: `linear-gradient(#E6EE9C, #F99158)`,
        //backgroundImage: `url(${bgImg})`,
        //backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "50px",
      }}
      variants={containerVariants}
      animate="visible"
      initial="hidden"
    >
      <img src={bgImg} height={"100%"} />

      <motion.div
        variants={childVariants}
        style={{
          //backgroundImage: `url(${bgImg2})`,
          //backgroundSize: "cover",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "25px",
        }}
      >
        <img src={bgImg2} height={"100%"} />
      </motion.div>
      <Assistant
        src={video}
        position={{
          zIndex: 10,
          position: "absolute",
          bottom: "30px",
          left: "30px",
        }}
      />

      <Navigation back="/p17" forward="/p19" />
    </motion.div>
  );
}
