import React, { useEffect, useState } from "react";
import { getCookie } from "../utils/manageCookies";

import Navigation from "../components/Navigation";
import LateralSimulation from "../components/LateralSimulation";
import PlotTorta from "../components/plots/PlotTorta";

import simulationRequest from "../utils/simulationRequest";

import video from "../assets/video/SIM_Risultato_Regolamento.mp4";

export default function P24() {
  const [serverReply, setServerReply] = useState({});
  const [datafield, setDatafield] = useState([]);

  useEffect(() => {
    const sessionID = getCookie("sessionID");
    //console.log(`Sessione ${sessionID}`);
    simulationRequest({ sessionID }, "regolamento", setServerReply);
    //console.log(serverReply);

    setDatafield([
      { category: "Consumatori", value: 100 * serverReply?.ripartizione },
      {
        category: "Produttori",
        value: 100 * (1 - serverReply?.ripartizione),
      },
    ]);
  }, [serverReply?.ripartizione]);

  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        display: "flex",
      }}
    >
      <LateralSimulation video={video} />
      <div
        style={{
          height: "100%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        <p style={{ fontSize: "1.5em", fontWeight: 100 }}>
          Ripartizione degli incentivi votata dalla CER
        </p>
        {datafield && (
          <PlotTorta
            datafield={datafield}
            divStyle={{
              width: "100%",
              height: "45%",
            }}
          />
        )}
        <div
          style={{
            width: "100%",
            borderStyle: "solid",
            borderWidth: "4px 0px 0px",
            borderColor: "#BDBDBD",
          }}
        >
          <p
            style={{ textAlign: "center", fontSize: "1.5em", fontWeight: 100 }}
          >
            Ripartizione <br />
            <span style={{ fontWeight: 700, fontSize: "1.5em" }}>
              {serverReply.proporzionale
                ? "proporzionale ai kWh consumati/prodotti"
                : "equamente distribuito tra tutti i consumatori/produttori"}
            </span>
          </p>
        </div>
      </div>

      <Navigation back="/p23" forward="/p25" />
    </div>
  );
}
