import React from "react";

import Assistant from "../components/Assistant";

function LateralSimulation({ video }) {
  return (
    <div
      style={{
        height: "100%",
        width: "20%",
        paddingRight: "20px",
        paddingLeft: "20px",
        backgroundImage: `linear-gradient(#F99158, #EF5350)`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <p
        style={{
          color: "white",
          fontSize: "3em",
          fontWeight: 100,
        }}
      >
        La Vostra <span style={{ fontWeight: 700 }}>CER</span>
      </p>
      <Assistant
        src={video}
        position={{
          zIndex: 10,
          marginBottom: "30px",
        }}
      />
    </div>
  );
}

export default LateralSimulation;
