import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { getCookie } from "../utils/manageCookies";

const useAmico = () => {
  const [amicoCER, setAmicoCER] = useState();

  useEffect(() => {
    const isToken = () => {
      const token = getCookie("token");
      if (token) {
        const { amico } = jwt_decode(token);
        setAmicoCER(amico);
      }
    };

    isToken();
  }, []);

  return { amicoCER };
};

export default useAmico;
