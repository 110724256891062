import React, { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function PlotStackedBar({ datafield, divStyle }) {
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");

    // Set themes
    var myTheme = am5.Theme.new(root);

    myTheme.rule("Label").setAll({
      fill: am5.color(0x252525),
      fontSize: "1.2em",
      fontFamily: "BioRhyme",
      fontWeight: "300",
    });

    myTheme.rule("Grid", ["base"]).setAll({
      strokeOpacity: 0.1,
    });

    //color set
    myTheme
      .rule("ColorSet")
      .set("colors", [
        am5.color(global.producerColor),
        am5.color(global.consumiBuoniColor),
        am5.color(global.consumerColor),
      ]);

    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root), myTheme]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "panY",
        wheelY: "zoomY",
        layout: root.verticalLayout,
      })
    );

    var data = [
      {
        kwh: "kWh",
        produzione: 2.5,
        scambio: 2.5,
        consumo: 2.1,
      },
    ];

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var yRenderer = am5xy.AxisRendererY.new(root, {});
    var yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "kwh",
        renderer: yRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    yRenderer.grid.template.setAll({
      location: 1,
    });

    yAxis.data.setAll(data);

    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererX.new(root, {
          strokeOpacity: 0.1,
        }),
      })
    );

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(name, fieldName) {
      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          stacked: true,
          xAxis: xAxis,
          yAxis: yAxis,
          baseAxis: yAxis,
          valueXField: fieldName,
          categoryYField: "kwh",
        })
      );

      series.columns.template.setAll({
        tooltipText: "{name}: {valueX.formatNumber('#.#')} kWh",
        tooltipY: am5.percent(90),
      });
      series.data.setAll(datafield);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();

      legend.data.push(series);
    }

    makeSeries("Produzione", "produzione");
    makeSeries("Consumo buono", "scambio");
    makeSeries("Consumo", "consumo");

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [datafield]);

  return <div id="chartdiv" style={divStyle}></div>;
}

export default PlotStackedBar;
