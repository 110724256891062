import React from "react";
import { motion } from "framer-motion";

import Navigation from "../components/Navigation";
import Assistant from "../components/Assistant";

import video from "../assets/video/Intro_Esempio.mp4";

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.6,
    },
  },
};

const childVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 1 },
  },
};

export default function P15() {
  return (
    <motion.div
      style={{
        height: "100%",
        backgroundImage: `linear-gradient(#E6EE9C, #F99158)`,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      variants={containerVariants}
      animate="visible"
      initial="hidden"
    >
      <motion.p
        variants={childVariants}
        style={{
          color: "white",
          fontSize: "3em",
        }}
      >
        Esempio
      </motion.p>

      <motion.div variants={childVariants}>
        <Assistant
          src={video}
          position={{
            zIndex: 10,
            position: "absolute",
            bottom: "30px",
            left: "30px",
          }}
        />
      </motion.div>

      <Navigation back="/p14" forward="/p16" />
    </motion.div>
  );
}
