import React from "react";
import { motion } from "framer-motion";

import Navigation from "../components/Navigation";

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.5,
    },
  },
};

const childVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.8 },
  },
};

function P6() {
  return (
    <motion.div
      variants={containerVariants}
      animate="visible"
      initial="hidden"
      style={{
        backgroundImage: `linear-gradient(#1DE9B6, #E6EE9C)`,
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <p style={{ color: "#fff", fontSize: "5em", fontWeight: 300, margin: 0 }}>
        CER
      </p>
      <div
        style={{
          margin: 0,
          marginBottom: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <motion.p
          variants={childVariants}
          style={{
            color: "#fff",
            fontSize: "4em",
            fontWeight: 400,
            margin: 0,
            paddingRight: 10,
            paddingLeft: 10,
          }}
        >
          Comunità
        </motion.p>
        <motion.p
          variants={childVariants}
          style={{
            color: "#fff",
            fontSize: "4em",
            fontWeight: 400,
            margin: 0,
            paddingRight: 10,
            paddingLeft: 10,
          }}
        >
          Energetica
        </motion.p>
        <motion.p
          variants={childVariants}
          style={{
            color: "#fff",
            fontSize: "4em",
            fontWeight: 400,
            margin: 0,
            paddingRight: 10,
            paddingLeft: 10,
          }}
        >
          Rinnovabile
        </motion.p>
      </div>

      <Navigation back="/p5" forward="/p7" />
    </motion.div>
  );
}

export default P6;
