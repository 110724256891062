import React, { useState } from "react";

function FakeUserCard({ img, name, value, setValue, kW = false }) {
  const [leftColor, setLeftColor] = useState("white");
  const [rightColor, setRightColor] = useState("white");

  return (
    <div
      style={{
        flex: 1,
        height: "100%",
        borderRadius: 20,
        boxShadow: "0px 0px 24px 0px rgba(0, 0, 0, 0.16)",
        margin: 15,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          height: "75%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingBottom: "20px",
        }}
      >
        <p
          style={{
            margin: "10px 0px",
            height: "25%",
            color: "#BDBDBD",
            fontFamily: "Roboto",
          }}
        >
          {name}
        </p>
        <img src={img} style={{ height: "75%" }} />
      </div>
      <div
        style={{
          height: "25%",
          width: "100%",
          border: "1px solid #E0E0E0",
          borderRadius: "0px 0px 20px 20px",
          display: "flex",
        }}
      >
        <div
          style={{
            flex: 1,
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            backgroundColor: leftColor,
            borderRadius: "0px 0px 0px 20px",
          }}
          onMouseDown={() => {
            setLeftColor("#eee");
          }}
          onMouseUp={() => {
            setLeftColor("white");
          }}
          onClick={() => {
            setValue(Math.max(0, value - (kW ? 3 : 1)));
          }} //remove 1 element or 3kW. Not less then 0
        >
          <p
            style={{
              color: "#828282",
              fontSize: "2em",
              fontFamily: "Roboto",
              fontWeight: 800,
              margin: 0,
              padding: 0,
            }}
          >
            -
          </p>
        </div>
        <div
          style={{
            flex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#E0E0E0",
            height: "100%",
          }}
        >
          <p
            style={{
              color: "black",
              fontSize: "2em",
              fontFamily: "Roboto",
              fontWeight: 800,
              margin: 0,
              padding: 0,
            }}
          >
            {value}{" "}
            {kW && (
              <span
                style={{
                  fontSize: "0.5em",
                  fontWeight: 500,
                }}
              >
                kW
              </span>
            )}
          </p>
        </div>
        <div
          style={{
            flex: 1,
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: rightColor,
            borderRadius: "0px 0px 20px 0px",
          }}
          onMouseDown={() => {
            setRightColor("#eee");
          }}
          onMouseUp={() => {
            setRightColor("white");
          }}
          onClick={() => {
            setValue(value + (kW ? 3 : 1));
          }} //add 1 element or 3kW
        >
          <p
            style={{
              color: "#828282",
              fontSize: "2em",
              fontFamily: "Roboto",
              fontWeight: 800,
              margin: 0,
              padding: 0,
              cursor: "pointer",
            }}
          >
            +
          </p>
        </div>
      </div>
    </div>
  );
}

export default FakeUserCard;
