import React, { useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

function PlotArea({ datafield, divStyle }) {
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdiv");

    // Set themes
    var myTheme = am5.Theme.new(root);

    myTheme.rule("Label").setAll({
      fill: am5.color(0x252525),
      fontSize: "1.2em",
      fontFamily: "BioRhyme",
      fontWeight: "300",
    });

    //color set
    myTheme
      .rule("ColorSet")
      .set("colors", [
        am5.color(global.consumerColor),
        am5.color(global.producerColor),
        am5.color(global.consumiBuoniColor),
      ]);

    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root), myTheme]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
      })
    );

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    var cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        max: 24,
        strictMinMax: true,
        numberFormat: "#:00",
        maxPrecision: 0,
        y: am5.percent(30),
        renderer: am5xy.AxisRendererX.new(root, {}),
      })
    );

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "#' kWh'",
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "cons",
        valueXField: "hidx",
        tooltip: am5.Tooltip.new(root, {
          labelText: "Consumo: {valueY.formatNumber('#.##')} kWh",
        }),
      })
    );

    var series2 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "prod",
        //openValueYField: "cons",
        valueXField: "hidx",
        //stroke: series.get("stroke"),
        //fill: series.get("stroke"),
        tooltip: am5.Tooltip.new(root, {
          labelText: "Produzione: {valueY.formatNumber('#.##')} kWh",
        }),
      })
    );

    var series3 = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "incentivato",
        //openValueYField: "cons",
        valueXField: "hidx",
        stroke: false,
        //stroke: series.get("stroke"),
        //fill: series.get("stroke"),
        tooltip: am5.Tooltip.new(root, {
          labelText: "Incentivato: {valueY.formatNumber('#.##')} kWh",
        }),
      })
    );

    series3.fills.template.setAll({
      fillOpacity: 0.3,
      visible: true,
    });

    series.strokes.template.set("strokeWidth", 2);
    series2.strokes.template.set("strokeWidth", 2);
    series3.strokes.template.set("strokeWidth", 0);

    // Set data
    series.data.setAll(datafield);
    series2.data.setAll(datafield);
    series3.data.setAll(datafield);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);
    series2.appear(1000);
    series3.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [datafield]);

  return <div id="chartdiv" style={divStyle}></div>;
}

export default PlotArea;
