import React from "react";
import { motion } from "framer-motion";

import Navigation from "../components/Navigation";
import Assistant from "../components/Assistant";

import useAmico from "../hooks/useAmico";

import video from "../assets/video/Intro_CERtrude.mp4";
import bgImg2 from "../assets/sfondoIntro.jpg";
import logo from "../assets/light_logo.png";
import logoAris from "../assets/aris_logo_bgalpha.png";

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      delayChildren: 0.5,
      staggerChildren: 1.5,
    },
  },
};

const childVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 2 },
  },
};

export default function Intro() {
  const { amicoCER } = useAmico();

  return (
    <motion.div
      style={{ height: "100%" }}
      variants={containerVariants}
      animate="visible"
      initial="hidden"
    >
      <div
        style={{
          backgroundImage: `url(${bgImg2})`,
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      ></div>
      <motion.div
        variants={childVariants}
        style={{
          backgroundImage: `url(${bgImg2})`,
          backgroundSize: "cover",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <motion.div
          variants={childVariants}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <Assistant src={video} />
          <div
            style={{
              width: "50%",
              height: "12%",
              borderRadius: "100px",
              backgroundColor: "white",
              marginTop: 100,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 6vw",
            }}
          >
            <p
              style={{
                width: "33%",
                margin: 0,
                fontSize: "1em",
                color: "#0097A7",
                fontWeight: 100,
                textAlign: "left",
              }}
            >
              CERtrude è un prodotto di{" "}
            </p>
            <img src={logo} height={"80%"} />
            <p
              style={{
                width: "33%",
                margin: 0,
                fontSize: "1em",
                color: "#0097A7",
                fontWeight: 100,
                textAlign: "right",
              }}
            >
              www.likelight.it
            </p>
          </div>
          <div
            style={{
              width: "20%",
              height: "12%",
              borderRadius: "100px",
              backgroundColor: "white",
              marginTop: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0px 6vw",
              gap: 50,
            }}
          >
            {amicoCER == "ArisFormazione" && (
              <>
                <p style={{ fontSize: "1em", color: "#0097A7" }}>
                  Supportato da
                </p>
                <img src={logoAris} height={"80%"} />
              </>
            )}
          </div>
        </motion.div>
      </motion.div>

      <Navigation
        back="/"
        forward={amicoCER == "ArisFormazione" ? "/intro_aris" : "/p1"}
      />
    </motion.div>
  );
}
