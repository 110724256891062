import React, { useEffect, useState } from "react";
import { getCookie } from "../utils/manageCookies";

import Navigation from "../components/Navigation";
import LateralSimulation from "../components/LateralSimulation";
import PlotStackedBar from "../components/plots/PlotStackedBar";

import simulationRequest from "../utils/simulationRequest";

import video from "../assets/video/SIM_Analisi_Globale.mp4";

export default function P21() {
  const [serverReply, setServerReply] = useState({});
  const [datafield, setDatafield] = useState([]);

  useEffect(() => {
    const sessionID = getCookie("sessionID");
    simulationRequest({ sessionID }, "globale", setServerReply);
    //result:{produzione, consumo, scambio, incentivo}

    setDatafield([
      {
        kwh: "kWh",
        produzione: serverReply.produzione,
        scambio: serverReply.scambio,
        consumo: serverReply.consumo,
      },
    ]);
  }, [serverReply.produzione]);

  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100%",
        width: "100%",
        display: "flex",
      }}
    >
      <LateralSimulation video={video} />
      <div
        style={{
          height: "100%",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
          paddingLeft: "10%",
          paddingRight: "10%",
        }}
      >
        <p style={{ fontSize: "1.5em", fontWeight: 100 }}>
          Analisi globale annuale
        </p>
        <PlotStackedBar
          datafield={datafield}
          divStyle={{
            width: "100%",
            height: "33%",
          }}
        />
        <table width="100%">
          <tr align="center" style={{ fontSize: "1.2em", fontWeight: 100 }}>
            <td>Produzione</td>
            <td>Consumo buono</td>
            <td>Consumo</td>
          </tr>
          <tr align="center" style={{ fontSize: "1.75em", fontWeight: 700 }}>
            <td style={{ color: global.producerColor }}>
              {serverReply.produzione && serverReply?.produzione.toFixed(0)} kWh
            </td>
            <td style={{ color: global.consumiBuoniColor }}>
              {serverReply.scambio && serverReply?.scambio.toFixed(0)} kWh
            </td>
            <td style={{ color: global.consumerColor }}>
              {serverReply.consumo && serverReply?.consumo.toFixed(0)} kWh
            </td>
          </tr>
        </table>
        <div
          style={{
            width: "100%",
            borderStyle: "solid",
            borderWidth: "4px 0px 0px",
            borderColor: "#BDBDBD",
          }}
        >
          <p
            style={{ textAlign: "center", fontSize: "1.5em", fontWeight: 100 }}
          >
            Incentivo economico totale <br />
            <span style={{ fontWeight: 700, fontSize: "1.5em" }}>
              {serverReply.incentivo && serverReply?.incentivo.toFixed(2)} €
            </span>
          </p>
        </div>
      </div>

      <Navigation back="/p20" forward="/p22" />
    </div>
  );
}
