import React from "react";
import { motion } from "framer-motion";

import Navigation from "../components/Navigation";

import bgImg2 from "../assets/sfondoIntro.jpg";
import logo from "../assets/aris_logo_bgalpha.png";
import { Grid, Typography } from "@mui/material";

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      delayChildren: 0.5,
      staggerChildren: 1.5,
    },
  },
};

const childVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 2 },
  },
};

export default function IntroAris() {
  return (
    <motion.div
      style={{
        height: "100%",
        backgroundImage: `url(${bgImg2})`,
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
      }}
      variants={containerVariants}
      animate="visible"
      initial="hidden"
    >
      <motion.div
        variants={childVariants}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Typography
          style={{ color: "#fff", fontWeight: 700 }}
          variant="h3"
          mt={4}
          mb={10}
        >
          Benvenuto da Aris
        </Typography>
        <Grid container alignItems="center" spacing={4} px={20}>
          <Grid item xs={3}>
            <img src={logo} width="100%" />
          </Grid>
          <Grid item xs={9}>
            <Typography style={{ color: "white", fontSize: "1.1rem" }}>
              Dal 1987, ARIS Formazione e Ricerca Società Cooperativa eroga,
              all’intero movimento cooperativo regionale, servizi specializzati
              nei settori:
            </Typography>
            <ul style={{ color: "white" }}>
              <li>
                <Typography
                  variant="h6"
                  style={{ color: "white", fontSize: "1.4rem" }}
                >
                  Formazione e ricerca
                </Typography>{" "}
              </li>
              <li>
                <Typography
                  variant="h6"
                  style={{ color: "white", fontSize: "1.4rem" }}
                >
                  Progettazione partecipata (community engagement, business
                  model innovativi, co-progettazione con le comunità, analisi
                  dell’impatto sociale)
                </Typography>
              </li>
            </ul>
            <Typography style={{ color: "white", fontSize: "1.1rem" }}>
              È accreditata come ente formativo presso la Regione Umbria.
            </Typography>
            <Typography
              style={{
                color: "white",
                marginTop: "0.5rem",
                fontSize: "1.1rem",
              }}
            >
              L’eccellenza di Aris risiede nella capacità di leggere,
              interpretare e soddisfare nel modo più efficace i bisogni delle
              imprese, delle persone che vi lavorano, dei territori in cui
              operano.
            </Typography>
            <Typography
              style={{
                color: "white",
                marginTop: "0.5rem",
                fontWeight: 700,
                fontSize: "1.1rem",
              }}
            >
              ARIS sostiene la nascita di Comunità Energetiche Rinnovabili
              perché crede nella capacità dei cittadini di fare rete e
              contrastare la povertà energetica attraverso la proposta di
              soluzioni smart e sostenibili, per una transizione energetica più
              equa e giusta, alla portata di tutti e tutte.
            </Typography>
          </Grid>
        </Grid>
      </motion.div>
      <motion.div
        variants={childVariants}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          px={20}
          mt={6}
          gap={10}
          alignItems="flex-end"
          style={{ color: "white" }}
        >
          <Typography variant="h6" style={{ fontSize: "1.4rem" }}>
            www.arisformazione.it
          </Typography>
          <Typography variant="h6" style={{ fontSize: "1.4rem" }}>
            www.green-community.it
          </Typography>
        </Grid>
      </motion.div>
      <Navigation back="/intro" forward="/p1" />
    </motion.div>
  );
}
