import React from "react";

import Navigation from "../components/Navigation";

//import video_test from "../assets/video/Legge.mp4";
import logo from "../assets/aris_logo_bgalpha.png";
import LateralMenu from "../components/LateralMenu";

export default function P8Aris() {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        backgroundColor: "white",
      }}
    >
      <LateralMenu selected={2} image={logo} />
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: "8%",
          paddingRight: "8%",
        }}
      >
        <h2>Perché una CER in forma cooperativa?</h2>
        <ul style={{ color: "#333333", fontSize: "1.5em", fontWeight: 100 }}>
          <li style={{ marginBottom: 20 }}>
            <span style={{ fontWeight: 700 }}>
              Proprietà e controllo democratico:
            </span>{" "}
            Nella cooperativa, ogni membro ha una voce e un voto
            indipendentemente dalla quantità di capitale investito. Questo
            favorisce la partecipazione attiva e il coinvolgimento di tutti i
            membri nella gestione dell'attività.
          </li>
          <li style={{ marginBottom: 20, marginTop: 20 }}>
            <span style={{ fontWeight: 700 }}>
              Sostenibilità e responsabilità sociale:{" "}
            </span>{" "}
            Le cooperative hanno spesso una forte vocazione sociale e
            un’impronta ambientale positiva.
          </li>
          <li style={{ marginBottom: 20, marginTop: 20 }}>
            <span style={{ fontWeight: 700 }}>
              Contrasto alla povertà energetica interna ed esterna alla
              cooperativa:{" "}
            </span>
            I membri della cooperativa approvano un regolamento per la
            condivisione degli incentivi generati dallo scambio dell’energia e
            questo modello promuove la solidarietà e l'equità.
          </li>
        </ul>
      </div>

      <Navigation back="/p8" forward="/p8_aris_2" />
    </div>
  );
}
